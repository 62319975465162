import React from 'react'
import '../styles/index.css'
import { resiko } from '../hardcoded/resiko';

const Footer = () => {
  return (
    <>
      <div className="text-white bg-[#1F2433] py-10 font-extralight">
        <div className="w-5/6 flex md:justify-center justify-start py-9 mx-auto font-body">
          <div className="grid md:grid-cols-4 gap-8 text-sm">
            <div>
              <p className="font-bold mb-3">PT IKI Karunia Indonesia</p>
              <p className="font-medium">Graha Anabatic 11th Floor</p>
              <p className="font-medium">
                Jl. Scientia Boulevard Kav.U2, Summarecon
              </p>
              <p className="font-medium">
                Serpong, Kec. Kelapa Dua, Tangerang, Banten
              </p>
              <p className="font-medium">15810, Indonesia</p>
              
              <div className="container flex sm:flex-row flex-col mt-8">
                <div className="flex flex-col sm:items-center my-2 sm:my-0">
                  <p className="font-bold text-[12px]">
                    {" "}
                    Berizin dan diawasi oleh
                  </p>
                  <img
                    className="mt-5 w-[150px]"
                    src={require("../images/Otoritas Jasa Keuangan.png")}
                  ></img>
                </div>
                <div className="flex flex-col sm:mx-3 sm:items-center">
                  <p className="font-bold text-[12px]">
                    {" "}
                    Anggota terdaftar dari
                  </p>
                  <img
                    className="mt-5 w-[150px]"
                    src={require("../images/Asset Icons Website IKI Modal/Group 600.png")}
                  ></img>
                </div>
                <div className="flex flex-col justify-center">
                  <p className="font-bold text-[12px]">
                    Certified by
                  </p>
                  <img
                    className="mt-5 w-[70px]"
                    src={require("../images/Asset Icons Website IKI Modal/pngegg-2.png")}
                  ></img>
                </div>
              </div>
            </div>
            <div className="flex flex-col md:px-8">
              <p className="font-bold mb-3 text-[20px]">Perusahaan</p>
              <a href="./tentang-kami" className="my-2">Tentang Kami</a>
              <a href="./tentang-kami" className="my-2">Laporan Keuangan</a>
              <a href="./legal-faq" className="my-2">Legal & FAQ</a>
            </div>
            <div className="flex flex-col">
              <p className="font-bold mb-3 text-[20px]">Produk</p>
              <a href="./beri-pinjaman" className="my-2">Beri Pinjaman</a>
              <a href="./ajukan-pinjaman" className="my-2">Ajukan Pinjaman</a>
              <a href={process.env.REACT_APP_PIJAR_URL} className="my-2">Pijar</a>
            </div>
            <div>
              <p className="font-bold mb-3 text-[20px]">Kontak</p>
              <p className="my-2">Call Center (Whatsapp):</p>
              <p className="my-2">081211117359</p>
              <p className="my-2">Email:</p>
              <p className="my-2">marketing@iki.id</p>
              <p className="my-2">helpdesk@iki.id</p>
            </div>
          </div>
        </div>
        <div className="container w-5/6 p-10 flex flex-col items-center text-center font-body mx-auto border rounded-xl border-red-700">
          <p className="font-extrabold text-[28px] mb-9">Disclaimer Resiko</p>
          <div className="text-left text-sm font-thin">
            {resiko.text.map((risk, i) => {
              if(i < 2){
                return (
                  <b><p key={i} className="mb-3">
                    {risk}
                  </p></b>
                );
              }else{
                return (
                  <p key={i} className="mb-3">
                    {risk}
                  </p>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer